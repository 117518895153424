import React, { Component } from 'react';
import {Switch, Route} from 'react-router';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Home from './faqet/Home';
import About from './faqet/About';
import HowWeWork from './faqet/HowWeWork';
import JoinUs from './faqet/JoinUs';
import Expertise from './faqet/Expertise';
import ExpertiseX from './faqet/ExpertiseX';
import Blog from './faqet/Blog';
import Test from './faqet/Test';
import WhyOutsourcing from './faqet/WhyOutsourcing';
import WhyPradium from './faqet/WhyPradium';
import WhyAlbania from './faqet/WhyAlbania';
import Contact from './faqet/Contact';
import ThankYou from './faqet/ThankYou';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              classNames="fade"
              timeout={300}
            >
            <Switch location={location}>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/howwework" component={HowWeWork} />
              <Route exact path="/joinus" component={JoinUs} />
              <Route exact path="/expertise" component={Expertise} />
              <Route exact path="/expertise/:which" component={ExpertiseX} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/whyoutsourcing" component={WhyOutsourcing} />
              <Route exact path="/whypradiumtech" component={WhyPradium} />
              <Route exact path="/whyalbania" component={WhyAlbania} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/thankyou" component={ThankYou} />
              <Route exact path="/test" component={Test} />
            </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
          />
      </div>
    );
  }
}

export default App;
