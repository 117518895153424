import React, {Component} from 'react';
import './ThreeThings.css';


class ThreeThings extends Component{
    render(){
        return(
            <div className="threeThings">
                {this.props.children}
            </div>
        )
    }
}

export default ThreeThings;