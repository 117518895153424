import React, {Component} from 'react';

import './ImageLink.css';

class ImageLink extends Component{
    constructor(props){
        super(props);
        this.state = {
            hovered: false
        }
        this.addHeight = this.addHeight.bind(this)
        this.removeHeight = this.removeHeight.bind(this)
    }
    addHeight(){
        this.setState({hovered: true})
    }

    removeHeight(){
        this.setState({hovered: false})
    }

    render(){
        return(
            <div className="imageLink" style={{backgroundImage: `url("${this.props.src}")`}} onMouseOver={this.addHeight} onMouseOut={this.removeHeight}>
                <div className="overlay"></div>
                <img src={this.props.skeleti} alt='skelet poliedri' style={{height: this.state.hovered ? '72%' : '70%', transform: this.state.hovered ? 'rotate(5deg)' : 'rotate(0)'}} />
                <div className="linkText">{this.props.text}</div>
            </div>
        )
    }
}

export default ImageLink;