import React, {Component} from 'react';
import './ShortInfo.css';

class ShortInfo extends Component{ 
    render(){
        return(
            <div className="shortInfo">
                   <p>{this.props.text}</p>
                </div>
        )
    }
}

export default ShortInfo;