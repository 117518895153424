import React, {Component} from 'react';
import provide1 from '../../imazhet/provide1.svg';
import provide2 from '../../imazhet/provide2.svg';
import provide3 from '../../imazhet/provide3.svg';
import provide4 from '../../imazhet/provide4.svg';
import './WeProvide.css';

function Provide({src, title, text}){ 
    return(
        <div className="provide">
            <img src={src} alt={title}></img>
            <h1 className="title">{title}</h1>
            <p>{text}</p>
        </div>
    )
}

class WeProvide extends Component{ 
    render(){
        return(
            <div className="weProvide">
                <h1 className="title">We Provide</h1>
                <div className="whatWeProvide">
                    <Provide src={provide1} title='Talented and Creative People' text='Professional software developers whose aim is to always keep customers satisfied.'/>
                    <Provide src={provide2} title='Custom Projects' text='Always eager to build high performance software that deliver valuable products to our clients.'/>
                    <Provide src={provide3} title='Reliability' text='Stability, trust and partnership guide our business. We are proud of employee tenures and turnover rates.'/>
                    <Provide src={provide4} title='Positive Culture' text='Our employees are as important as our clients. We support their professional development constantly and foster creative participation, creating loyalty and ownership.'/>
                </div>
            </div>
        )
    }
}

export default WeProvide;