import React, {Component} from 'react';
import './BigInfo.css';

class BigInfo extends Component{
    render(){
        const {tt, p1, p2, src} = this.props;
        return(
            <div className="bigInfo">
                 <h1 className="title">{tt}</h1>
                 <div className="cont">
                    <div className="left">
                        <p>{p1}</p>
                        <p>{p2}</p>
                    </div>
                    <div className="right">
                        <img src={src} alt={tt} />
                    </div>
                 </div>
            </div>
        )
    }
}

export default BigInfo;