import React, {Component} from 'react';
import './IconLinks.css';

class IconLinks extends Component{
    render(){
        return(
            <div className="iconLinks">
                <div className="icons">
                    <div className="half">
                        <div className="iconLink">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.61 80.76" width='70' height='85' strokeWidth='2'>
                                <title>csd</title>
                                <path fill='#3fa9f5' d="M25.62,37.07V33.42l15.24-6.2v4.12L31,35.14l9.83,3.77v4.38Z" />
                                <path fill='#3fa9f5' d="M53,19.32h4.3L47.7,44.43H43.29Z" />
                                <path fill='#3fa9f5' d="M59.75,39.15l9.83-3.8-9.83-3.77V27.21L75,33.42v3.66l-15.24,6.2Z" />
                                <rect fill='none' stroke='#b5d1e9' x="1" y="1" width="98.61" height="64.42" rx="7.35" ry="7.35" />
                                <line fill='none' stroke='#b5d1e9' x1="59.16" y1="65.42" x2="61.55" y2="79.76" />
                                <line fill='none' stroke='#b5d1e9' x1="41.44" y1="65.42" x2="39.06" y2="79.76" />
                                <line fill='none' stroke='#b5d1e9' x1="28.59" y1="79.76" x2="72.02" y2="79.76" />
                            </svg>
                            <div className="desc">Custom Software Development</div>
                        </div>
                        <div className="iconLink">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.19 83" width='60' height='70' strokeWidth='2' strokeMiterlimit='10'>
                            <title>os_1</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                    <circle stroke='#b5d1e9' fill='none' cx="38.1" cy="41.46" r="37.1" />
                                    <circle stroke='#b5d1e9' fill='#fff' cx="52.77" cy="76.79" r="5.21" />
                                    <circle stroke='#b5d1e9' fill='#fff' cx="75.98" cy="36.48" r="5.21" />
                                    <circle stroke='#b5d1e9' fill='none' cx="21.02" cy="43.67" r="5.21" />
                                    <line stroke='#b5d1e9' fill='none' x1="12.71" y1="25.21" x2="15.14" y2="30.7" />
                                    <line stroke='#b5d1e9' fill='none' x1="16.37" y1="33.47" x2="18.79" y2="38.96" />
                                    <line stroke='#b5d1e9' fill='none' x1="14.46" y1="18.46" x2="41.27" y2="7.64" />
                                    <line stroke='#b5d1e9' fill='none' x1="18.26" y1="48.08" x2="11.61" y2="58.91" />
                                    <line stroke='#b5d1e9' fill='none' x1="8.39" y1="25.74" x2="8.75" y2="58.35" />
                                    <line stroke='#b5d1e9' fill='none' x1="14.3" y1="64.86" x2="47.55" y2="74.76" />
                                    <line stroke='#b5d1e9' fill='none' x1="48.53" y1="10.68" x2="55.29" y2="27.93" />
                                    <line stroke='#b5d1e9' fill='none' x1="25.74" y1="41.46" x2="50.2" y2="34.21" />
                                    <line stroke='#b5d1e9' fill='none' x1="55.29" y1="38.11" x2="51.91" y2="49.1" />
                                    <line stroke='#b5d1e9' fill='none' x1="51.45" y1="59.2" x2="52.77" y2="71.57" />
                                    <line stroke='#b5d1e9' fill='none' x1="25.74" y1="45.27" x2="45.72" y2="51.86" />
                                    <line stroke='#b5d1e9' fill='none' x1="22.79" y1="48.56" x2="49.85" y2="72.47" />
                                    <line stroke='#b5d1e9' fill='none' x1="60.38" y1="33.02" x2="71.08" y2="34.72" />
                                    <line stroke='#b5d1e9' fill='none' x1="55.29" y1="52.25" x2="71.9" y2="39.72" />
                                    <circle stroke='#3fa9f5' fill='#fff' cx="10" cy="20.91" r="5.09" />
                                    <circle stroke='#3fa9f5' fill='#fff' cx="9.25" cy="63.56" r="5.21" />
                                    <circle stroke='#3fa9f5' fill='#fff' cx="55.29" cy="33.02" r="5.09" />
                                    <circle stroke='#3fa9f5' fill='#fff' cx="50.42" cy="54.09" r="5.21" />
                                    <circle stroke='#3fa9f5' fill='#fff' cx="46.36" cy="6.09" r="5.09" />
                                    </g>
                                </g>
                            </svg>
                            <div className="desc">Outsorcing Services</div>
                        </div>
                        <div className="iconLink">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.98 65.15" width='60' height='75' strokeWidth='2' strokeMiterlimit='10' >
                            <defs>
                                <clipPath id="clip-path">
                                <polygon
                                    fill='none'
                                    points="29.57 16.02 29.57 33.64 46.98 33.64 46.98 49.96 12.86 49.96 12.86 16.02 29.57 16.02"
                                />
                                </clipPath>
                                <clipPath id="clip-path-2">
                                <rect fill='none' x="31.39" y="15.19" width="17.26" height="16.82" />
                                </clipPath>
                            </defs>
                            <title>ps</title>
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                <rect stroke='#b5d1e9' fill='transparent' x="1" y="1" width="96.98" height="63.15" />
                                <line stroke='#b5d1e9' x1="63.56" y1="35.93" x2="63.56" y2="56.69" />
                                <line stroke='#b5d1e9' x1="84.84" y1="25.47" x2="84.84" y2="56.69" />
                                <line stroke='#b5d1e9' x1="70.71" y1="10.07" x2="70.71" y2="56.69" />
                                <line stroke='#b5d1e9' x1="77.69" y1="10.07" x2="77.69" y2="56.69" />
                                <g clipPath="url(#clip-path)" >
                                    <circle fill='#3fa9f5' cx="29.57" cy="33.64" r="15.58" />
                                </g>
                                <g clipPath="url(#clip-path-2)">
                                    <circle fill='#3fa9f5' cx="31.39" cy="32.01" r="15.58" />
                                </g>
                                </g>
                            </g>
                            </svg>

                            <div className="desc">Project Services</div>
                        </div>
                    </div>
                    <div className="half">
                        <div className="iconLink">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.79 66.35" width='80' height='70' strokeWidth='2' strokeMiterlimit='10' >
                                <path stroke='#b5d1e9' fill='none' d="M100.79,41.8c0,5.77-5,10.46-11.21,10.46H18c-9.38,0-17-7.12-17-15.88,0-7.63,5.83-14.19,13.86-15.59a1.13,1.13,0,0,0,1-1C17,9.09,26.56,1,38.13,1A23.46,23.46,0,0,1,51.53,5.11a1.13,1.13,0,0,0,.29.22A21,21,0,0,1,59.6,15.88a1.11,1.11,0,0,0,.59.66,1.26,1.26,0,0,0,.92.06,23,23,0,0,1,7.2-1.16c10.09,0,18.65,6.43,19.93,15a1.36,1.36,0,0,0,1.35.92C95.77,31.34,100.79,36,100.79,41.8Z" />
                                <circle fill='#3fa9f5' cx="50.9" cy="52.26" r="14.09" />
                                <polyline stroke='#fff' fill='none' strokeLinecap='round' points="44.6 52.79 49.26 57.77 58.15 49.72" />
                            </svg>
                            <div className="desc">Cloud</div>
                        </div>
                        <div className="iconLink">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.79 66.35" width='75' height='85' strokeWidth='2' strokeMiterlimit='10' >
                                <title>ms</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                    <rect fill='#fff' stroke='#b5d1e9' x="9.59" y="9.98" width="72.26" height="62.89" />
                                    <rect fill='#fff' stroke='#b5d1e9' x="1" y="1" width="72.26" height="62.89" />
                                    <line fill='#fff' stroke='#b5d1e9' x1="1" y1="18.49" x2="73.26" y2="18.49" />
                                    <circle fill='#b5d1e9' cx="8.81" cy="9.98" r="1.96" />
                                    <circle fill='#b5d1e9' cx="15.02" cy="9.98" r="1.96" />
                                    <circle fill='#b5d1e9' cx="21.22" cy="9.98" r="1.96" />
                                    <rect fill='none' stroke='#3fa9f5' x="11.57" y="26.52" width="19.71" height="30.24" />
                                    <line fill='none' stroke='#3fa9f5' x1="37.82" y1="26.52" x2="67.5" y2="26.52" />
                                    <line fill='none' stroke='#3fa9f5' x1="37.82" y1="41.64" x2="67.5" y2="41.64" />
                                    <line fill='none' stroke='#3fa9f5' x1="37.82" y1="56.75" x2="67.5" y2="56.75" />
                                    </g>
                                </g>
                            </svg>
                            <div className="desc">Managed Services</div>
                        </div>
                        <div className="iconLink">
                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 36.35 68.81" width='70' height='75' strokeWidth='2' strokeMiterlimit='10' >
                                <title>ma</title>
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Layer_1-2" data-name="Layer 1">
                                    <rect
                                        stroke='#b5d1e9' fill='none'
                                        x="1"
                                        y="1"
                                        width="34.35"   
                                        height="66.81"
                                        rx="4.04"
                                        ry="4.04"
                                    />
                                    <path fill='#b5d1e9' d="M9.51,1.47s.77,2.1,2.23,2.1h6.65V1H9.51Z" />
                                    <path fill='#b5d1e9' d="M27.28,1.47S26.5,3.57,25,3.57H18.39V1h8.89Z" />
                                    <polygon
                                        stroke='#3fa9f5' fill='none' strokeLinejoin='round'
                                        points="18.53 17.43 8.59 23.39 18.53 29.56 27.76 23.5 18.53 17.43"
                                    />
                                    <polyline
                                        stroke='#3fa9f5' fill='none' strokeLinejoin='round'
                                        points="8.59 23.39 8.59 34.92 18.53 40.75 27.76 35.09 27.76 23.5"
                                    />
                                    <line stroke='#3fa9f5' strokeLinejoin='round' x1="18.53" y1="29.56" x2="18.53" y2="40.75" />
                                    <line stroke='#b5d1e9' fill='none' x1="13.51" y1="47.04" x2="22.84" y2="47.04" />
                                    <path stroke='#b5d1e9' fill='none' d="M5.13,53.15h26" />
                                    <path stroke='#b5d1e9' fill='none' d="M5.13,56.4h26" />
                                    <path stroke='#b5d1e9' fill='none' d="M5.13,59.66h26" />
                                    </g>
                                </g>
                            </svg>
                            <div className="desc">Mobile Applications</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default IconLinks;