import React, {Component} from 'react';
import Menu from './Menu';
import './GrandWhite.css';

class GrandWhite extends Component{
    render(){
        const {title, src, text} = this.props;
        return(
            <div>
                <Menu color='black' selected={this.props.selectedMenu}/>
                <div className="grandWhite"> 
                    <h1 className="title">{title}</h1>
                    <div className="imageText">
                        <img src={src} alt={title}/>
                        <p>{text}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default GrandWhite;