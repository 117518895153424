import React from 'react';
import {IoIosArrowRoundForward} from 'react-icons/io';
import './Table.css';

export default class Table extends React.Component{
    constructor(props){
        super(props);
        this.state = {sl: 0}
    }
    render(){ 
        return(
            <div className="tableContainer" ref={(el) => {this.table = el}} onScroll={() => this.setState({sl: this.table.scrollLeft})}>
                <div className="scrollLeft">Scroll <IoIosArrowRoundForward size={38} color='#3FA9F5'/></div>
                <table>
                    <thead>
                        <tr><th></th><th>Short Term</th><th>Long Term</th><th>Fixed Price Short Term</th><th>Fixed Price Long Term</th></tr>
                    </thead>
                    <tbody>
                        <tr><td>Value proposition</td><td>Staffing for Temporary work</td><td>Managed Services, Long term engagement and dedicated team</td><td>Fixed Price, Fixed Scope of work</td><td>Self-organizing teams</td></tr>
                        <tr><td>Project Managment</td><td>Client manages Team</td><td>Client and PT share project manager responsibilities</td><td>Customer product owner, PT Scrum Master</td><td>Customer Product Owner/PT Scrum master</td></tr>
                        <tr><td>Interviews with clients</td><td>Yes</td><td>Yes</td><td>Yes</td><td>PT shares most of the risk and builds the team</td></tr>
                        <tr><td>Risks</td><td>Low client risk</td><td>Low client risk. Lower costs, financial predictability</td><td>Slightly more expensive in planning project, Longer time to start, main risk on PT</td><td>Slightly more expensive in planning project, Longer time to start, main risk on PT</td></tr>
                        <tr><td>SLAs</td><td>Recruiting Timelines</td><td>Recruiting timelines Staff prerequisites, Project Delivery</td><td>Deliver quality, deliver timelines, deliver functional compliance, support and quality and timeline</td><td>Deliver quality, deliver timelines, deliver functional compliance, support and quality and timeline</td></tr>
                        <tr><td>Pricing Model/Billing</td><td>Fixed hourly fee per person, invoicing at the end of the month, based on timesheets</td><td>Fixed monthly fee per person, invoicing at the end of the month, based on timesheets</td><td>Invoicing based on pre-agreed steps</td><td>Invoicing based on pre-agreed steps</td></tr>
                    </tbody>
                </table>
            </div>
        )
    }
}