import React, {Component} from 'react';
import Head from '../pjeset/Expertise/Head';
import ImageLinks from '../pjeset/Shared/ImageLinks';
import webdesktop from '../imazhet/webdesktop.jpg';
import mobileapp from '../imazhet/mobileapp.jpg';
import managedservices from '../imazhet/managedservices.jpg';
import databaseossupport from '../imazhet/databaseossupport.jpg';
import customersupport from '../imazhet/customersupport.jpg';
import testing from '../imazhet/testing.jpg';
import skeleti1 from '../imazhet/skeleti4.svg';
import skeleti2 from '../imazhet/skeleti1.svg';
import skeleti3 from '../imazhet/skeleti5.svg';
import skeleti4 from '../imazhet/skeleti6.svg';
import skeleti5 from '../imazhet/skeleti7.svg';
import skeleti6 from '../imazhet/skeleti8.svg';
import Technologies from '../pjeset/Expertise/Technologies';
import ShortInfo from '../pjeset/Shared/ShortInfo';
import ApplicationForm from '../pjeset/Shared/ApplicationForm';
import Sitemap from '../pjeset/Shared/Sitemap';

import './Expertise.css';


class Expertise extends Component{
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        const imagelinks1 = [
            {bg: webdesktop, skeleti: skeleti1, to: '/expertise/webdesktop',text: 'Web and Desktop'},
            {bg: mobileapp, skeleti: skeleti2, to: '/expertise/mobileapps',text: 'Mobile Applications'},
            {bg: managedservices, skeleti: skeleti3, to: '/expertise/managedservices',text: 'Managed Services'}
        ]
        const imagelinks2 = [
            {bg: databaseossupport, skeleti: skeleti4, to: '/expertise/dbos',text: 'Database and OS Support'},
            {bg: customersupport, skeleti: skeleti5, to: '/expertise/csupport',text: 'Customer Support'},
            {bg: testing, skeleti: skeleti6, to: '/expertise/qatesting',text: 'QA and Testing'}
        ]
        return(
            <div id="expertise">
                <Head selectedMenu = 'Expertise'/>
                <ImageLinks imagelinks = {imagelinks1} />
                <ImageLinks imagelinks = {imagelinks2} />
                <Technologies />
                <ShortInfo text='Albania is one of the most promising software outsourcing destinations in Southeastern Europe, as it is considered a stable location with a well-desired position on the global outsourcing map. It is an EU candidate country and this is an advantage for companies interested in collaborating with Albanian firms, as legal and financial concerns are reduced to a minimum.' />
                <ApplicationForm />
                <Sitemap />
            </div>
        )
    }
}

export default Expertise;