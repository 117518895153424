import React from "react";

import './Form.css';

class Form extends React.Component{
  render(){
    return (
      <form action="contact_form.php" method="POST">
        <label htmlFor="email">Email*</label>
        <input type="email" name="email" />
        <label htmlFor="phone">Phone number*</label>
        <input type="tel" name="phone" />
        <button>Contact Us</button>
      </form>
    );
  }
}

export default Form;
