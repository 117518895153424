import React, {Component} from 'react';
import graph from '../../imazhet/graph.svg';
import './LongInfo.css';

class LongInfo extends Component{ 
    render(){
        return(
            <div className="longInfo">
                <div className="cont">
                    <div className="paragraphs">
                        <p><strong>Outsourcing today is considered as a way to expand, It’s cheaper and more efficient than adding staff, buying equipment and paying for new space to do it yourself.</strong></p>
                        <p>There is a cost savings of around 60% by outsourcing your work to PradiumTech, and the quality of the services provided is high thereby 
                        ensuring that low-cost does not mean low-quality. The most attractive aspect of nearshoring outsource model is the idea of control. Travel proximity, the ability to conduct calls and meetings within familiar timeline, putting little effort to adapting to new conditions or avoiding compromise of employee motivation within projects. Another significant aspect of outsourcing services to PradiumTech, is the similarity in regards to financial and legal constraints which offer a plus of stability within a collaborations of 
                        this kind.</p>
                        <p><strong>When used properly, outsourcing is one of the most effective strategies to business growth on today’s age as it is seen to be the most effective way to reduce expenses, and provides companies 
                        with a competitive advantage over rivals.</strong></p>
                    </div>
                    <div className="graph">
                        <img src={graph} alt='grafiku'></img>
                    </div>
                </div>
            </div>
        )
    }
}

export default LongInfo;