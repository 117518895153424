import React, {Component} from 'react';
import TwoThings from '../Shared/TwoThings';
import Itl from '../Shared/Itl';
import tech1 from '../../imazhet/tech1.svg';
import tech2 from '../../imazhet/tech2.svg';
import tech3 from '../../imazhet/tech3.svg';
import tech4 from '../../imazhet/tech4.svg';
import tech5 from '../../imazhet/tech5.svg';
import tech6 from '../../imazhet/tech6.svg';
import './Technologies.css';


class Technologies extends Component{ 
    render(){
        return(
            <div className="technologies">
                <TwoThings leftContent={<Itl src={tech1} title='Programming Languages' list={['C/C++', 'JAVA/J2EE', 'PHP', 'Python', 'JavaScript/jQuery' , 'JSON', 'XML/XSLT', 'HTML5', 'CSS3', 'PL/SQL', 'T-SQL', 'VB.Net/C#.Net' , 'ASP.Net']}/>}
                            rightContent={<Itl src={tech2} title='Frameworks' list={['AngularJS' , 'jQuery' , 'Node.JS' , 'React', 'Express', 'Apache', 'Symphony', 'Laravel', 'Drupal', 'Joomla', 'Django', 'AJAX', 'React Native', 'Angular Js + Ionic', 'Bootstrap', 'JQuery Mobile', 'Cocoa']}/>}
                />
                <TwoThings leftContent={<Itl src={tech3} title='Collaboration Platforms' list={['Magento', 'WordPress', 'Sharepoint', 'Liferay', 'OpenERP']}/>}
                            rightContent={<Itl src={tech4} title='Databases' list={['MySql', 'SQL Server', 'PostgreSQL']}/>}
                />
                <TwoThings leftContent={<Itl src={tech5} title='Operating Systems' list={['Windows Server', 'Ubuntu']}/>}
                            rightContent={<Itl src={tech6} title='Application Server Administration' list={['Tomcat', 'JBOSS', 'Apache', 'WebLogic', 'IIS']}/>}
                />
            </div>
        )
    }
}

export default Technologies;