import React, {Component} from 'react';
import Hero from '../pjeset/Home/Hero';
import IconLinks from '../pjeset/Home/IconLinks';
import ImageLinks from '../pjeset/Shared/ImageLinks';
import ApplicationForm from '../pjeset/Shared/ApplicationForm';
import whyoutsource from '../imazhet/whyoutsource.jpg';
import whyalbania from '../imazhet/whyalbania.jpg';
import whypradium from '../imazhet/whypradium.jpg';
import skeleti1 from '../imazhet/skeleti1.svg';
import skeleti2 from '../imazhet/skeleti2.svg';
import skeleti3 from '../imazhet/skeleti3.svg';
import Sitemap from '../pjeset/Shared/Sitemap';

import './Home.css';

class Home extends Component{
    constructor(props){
        super(props)
        this.state = {scrollTop: 0}
    }
    componentDidMount(){
        let that = this;
        window.addEventListener('scroll', ()=>{
            const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
            that.setState({scrollTop})
        })
        window.scrollTo(0, 0);
    }
    render(){
        const imagelinks = [
            {bg: whyoutsource, skeleti: skeleti1, to: '/whyoutsourcing',text: 'Outsorcing as growth strategy'},
            {bg: whyalbania, skeleti: skeleti3, to: '/whyalbania',text: 'Why Albania as an outsource opportunity'},
            {bg: whypradium, skeleti: skeleti2, to: '/whypradiumtech',text: 'Why outsource to PradiumTech'}
        ]
        return(
            <div id="home">
                <Hero selectedMenu = ''/>
                <IconLinks scrollTop={this.state.scrollTop}/>
                <ImageLinks imagelinks={imagelinks} />
                <ApplicationForm />
                <Sitemap />
            </div>
        )
    }
}

export default Home;