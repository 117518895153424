import React, {Component} from 'react';
import logo from '../../logo.svg';
import {Link} from 'react-router-dom';
import {IoLogoFacebook, IoLogoTwitter, IoLogoInstagram, IoLogoLinkedin} from 'react-icons/io';
import './Sitemap.css';

class Sitemap extends Component{
    
    render(){
        let viti = new Date().getFullYear();
        return(
            <footer className="sitemap">
                <div className="linkSpace">
                    <div className="linkColumn">
                        <Link className="logo" to="/"><img src={logo} alt="PradiumTech" /></Link>
                        <div className="social">
                            <IoLogoFacebook color='white' size={25}/>
                            <IoLogoTwitter color='white' size={25}/>
                            <IoLogoInstagram color='white' size={25}/>
                            <IoLogoLinkedin color='white' size={25}/>
                        </div>   
                    </div> 
                    <div className="linkColumn">
                        <h4>General Info</h4>    
                        <ul className="links">
                            <Link to='/'><li className="link">Home</li></Link>
                            <Link to='/about'><li className="link">About Us</li></Link>
                            <Link to='/howwework'><li className="link">How we work</li></Link>
                            <Link to='/'><li className="link">Terms and privacy</li></Link>
                        </ul>
                    </div>
                    <div className="linkColumn">
                        <h4>Discover</h4>    
                        <ul className="links">
                            <Link to='/expertise'><li className="link">Expertise</li></Link>
                            <Link to='/blog'><li className="link">TechBlog</li></Link>
                            <Link to='/whyoutsourcing'><li className="link">Outsorcing as Growth Strategy</li></Link>
                            <Link to='/whyalbania'><li className="link">Albania as an outsource Opportunity</li></Link>
                            <Link to='/whypradiumtech'><li className="link">Why outsource to PradiumTech</li></Link>
                        </ul>
                    </div>
                    <div className="linkColumn">
                        <h4>Contact</h4>    
                        <ul className="links">
                            <Link to='/joinus'><li className="link">Join Us</li></Link>
                            <li className="link">Address: Rruga Bardhok Biba, Nr 21, Tiranë, Albania</li>
                            <li className="link">info@pradiumtech.com</li>
         <li className="link">(NUIS) L91518008H</li>
                   <li className="link">Copyright 2019{viti !== 2019 ? ' - ' + viti : ''}</li>
                        </ul>
                    </div>
                </div>

            </footer>
        )
    }
}

export default Sitemap;