import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './JoinUsLink.css';

class JoinUsLink extends Component{
    render(){
        return(
            <div className="joinUsLink">
                <h1 className="title">Join Our Team</h1>
                <Link to='/contact'><button>Get In Touch</button></Link>
            </div>
        )
    }
}

export default JoinUsLink;