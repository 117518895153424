import React, {Component} from 'react';
import Menu from './Menu';
import './GrandImageLink.css';

class GrandImageLink extends Component{
    render(){
        return(
            <div>
                <Menu color={'white'} selected={this.props.selectedMenu}/>
                <div className="grandImageLink" style={{backgroundImage: `url("${this.props.src}")`}}>
                    <div className="overlay"></div>
                    <img src={this.props.skeleti} style={{display: this.props.skeleti ? 'block' : 'none'}} alt=''/>
                    <div className="linkText">{this.props.text}</div>
                </div>
            </div>
        )
    }
}

export default GrandImageLink;