import React, {Component} from 'react';
import Menu from '../Shared/Menu';
import poliedri1 from '../../imazhet/poliedri1.png';
import poliedri2 from '../../imazhet/poliedri2.png';
import poliedri3 from '../../imazhet/poliedri3.png';
import poliedri4 from '../../imazhet/poliedri4.png';

import './Hero.css';

let pages = [{
        bold: ['We are a <span style="color: #3FA9F5">technology</span> and', 
               '<span style="color: #3FA9F5">outsourcing software</span> company'
        ], 
        paragraph: ['<span class="paragraph">We deliver value oriented software services to companies that can’t afford to compromise on quality.</span>',
                    '<span class="paragraph">We are committed to add extra value to our customers based on our emphasis on performance, our strategic partnerships and continuous organizational and processes optimization.</span>'
        ]
    },
    {
        bold: ['Discover <span style="color: #3FA9F5">the benefits</span> of', 
                'software outsourcing with',
                'one of the <span style="color: #3FA9F5">leading providers</span>'
        ], 
        paragraph: ['Outsource with confidence.']
    },
    {
        bold: ['Leading IT Academy',
               '<span style="color: #3FA9F5">You are the future</span>'
        ], 
        paragraph: ['Software Engineering',
                    'Web Design',
                    'Cloud Computing'
        ]
    },
    {
        bold: ['Join Our Team<ul><li>Frontend Developer</li><li>Backend Developer</li><li>Mobile App Developer</li></ul>', '<a href="/#/contact"><button>Apply now</button></a>'], 
        paragraph: []
    }]

class Hero extends Component{
    constructor(props){
        super(props);
        this.state = {
            page: 0,
            status: ''
        }
    }
    componentDidMount(){
        let that = this;
        let time = 8000;
        function change(){
            setTimeout(() => {
                that.setState({status: 'down'})
            }, time - 1000);
            setTimeout(() => {
                that.setState({status: 'up', page: that.state.page < (pages.length - 1) ? that.state.page + 1 : 0})
            }, time - 500);
            setTimeout(() => {
                that.setState({status: ''})
            }, time);
        }
        change();
        setInterval(() => {
            change()
        }, time);
    }
    render(){
        let bolds = pages[this.state.page].bold.map((b,i)=>{
            return <span key={i} className="wrap" style={{alignSelf: this.state.page === 3 ? 'center' : 'flex-start', textAlign: this.state.page === 3 ? 'center' : 'left', width: this.state.page === 3 ? '80%' : 'auto'}}><div className={this.state.status} dangerouslySetInnerHTML={{__html: b}}></div></span>
        });
        let pars = pages[this.state.page].paragraph.map((p,i)=>{
            return <span key={i} className="wrap"><p className={this.state.status}dangerouslySetInnerHTML={{__html: p}}></p></span>
        });
        return(
            <div className="hero">
                <div className="circles"></div>
                <Menu color={'white'} selected={this.props.selectedMenu}/>
                <div className="bold" style={{alignSelf: this.state.page === 3 ? 'center' : 'flex-start', 
                                              padding: this.state.page === 3 ? '0' : '0 0 2% 5%',
                                              transform: this.state.page === 3 || this.state.page === 1 ? 'translateY(23%)' : 'translateY(0)'}}>
                    {bolds}
                </div>
                <div className="poliedrat">
                    <img className="poliedri1" src={poliedri1} alt="fluctuating polyhedron" />
                    <img className="poliedri2" src={poliedri2} alt="fluctuating polyhedron" />
                    <img className="poliedri3" src={poliedri3} alt="fluctuating polyhedron" />
                    <img className="poliedri4" src={poliedri4} alt="fluctuating polyhedron" />
                </div>
                <div className="bottom">
                    <div className="info" style={{transform: this.state.page === 3 || this.state.page === 1 ? 'translateY(23%)' : 'translateY(0)'}}>
                        {pars}
                    </div>
                    <div className="bottomRight">
                        <div className={`blueLine ${this.state.status.replace('down', '')}animateline`}></div>
                        <div className="pages"><span className="wrap"><div id="pageNr" className={this.state.status}>{this.state.page + 1}</div></span>/ 4</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Hero;