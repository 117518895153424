import React, {Component} from 'react';
import GrandWhite from '../pjeset/Shared/GrandWhite';
import ThreeThings from '../pjeset/About/ThreeThings';
import Itl from '../pjeset/Shared/Itl';
import aboutImg from '../imazhet/about_1.jpg';
import about1 from '../imazhet/about1.svg';
import about2 from '../imazhet/about2.svg';
import about3 from '../imazhet/about3.svg';
import ShortInfo from '../pjeset/Shared/ShortInfo';
import ApplicationForm from '../pjeset/Shared/ApplicationForm';
import Sitemap from '../pjeset/Shared/Sitemap';

class About extends Component{
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div id="about">
                <GrandWhite 
                    selectedMenu = 'About us'
                    height='auto' 
                    title='Great products come from great partnerships, first class people and the right technologies.'
                    src={aboutImg}
                    text='PradiumTech is a technology and outsourcing software company committed to deliver innovation through software services. We strive excellence in our products based on our emphasis on performance, our strategic partnerships and continuous organizational and processes optimization. We focus on accelerating software development through a blend of first-rate people and technologies, acknowledged processes and methodologies and forward-thinking employee development programs, all combined to deliver state-of-the-art solutions to our customers.'
                />
                <ThreeThings>
                    <Itl src={about1} title='Mission' text='Our main mission is to provide companies with innovative software solutions using emerging technologies at an affordable cost.' />
                    <Itl src={about2} title='Values' text='PradiumTech is governed by its core values. They guide our behaviour, decision-making, shaping our culture and defining our company’s character. Professional people, Integrity, Positive Engagement, Efficiency and Quality.' />
                    <Itl src={about3} title='Vision' text='Great products come from great partnerships, first class people and appropriate technologies. We believe in long term strategic partnerships and close relationships with our customers and partners.' />
                </ThreeThings>
                <ShortInfo text='Albania is one of the most promising software outsourcing destinations in Southeastern Europe, as it is considered a stable location with a well-desired position on the global outsourcing map. It is an EU candidate country and this is an advantage for companies interested in collaborating with Albanian firms, as legal and financial concerns are reduced to a minimum.'/>
                <ApplicationForm />
                <Sitemap />
            </div>
        )
    }
}

export default About;