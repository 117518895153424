import React, {Component} from 'react';
import GrandImageLink from '../pjeset/Shared/GrandImageLink';
import ShortInfo from '../pjeset/Shared/ShortInfo';
import Provide from '../pjeset/WhyPradium/WeProvide';
import Sitemap from '../pjeset/Shared/Sitemap';
import i2 from '../imazhet/whypradium.jpg';
import skeleti2 from '../imazhet/skeleti2.svg';

class WhyPradium extends Component{
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div id="whyPradium">
                <GrandImageLink selectedMenu = '' src={i2} skeleti={skeleti2} text="Why outsource to PradiumTech"/>
                <ShortInfo text='Outsourcing has proved to be a more predictable, scalable and reliable way to develop your business further. PradiumTech believes that it has the right ingredients to become a strategic partner in IT outsourcing services for companies all over the world. It houses a mix of professional employees with various language skills and a great academy program delivering up to date expertise.' />
                <Provide />
                <Sitemap />
            </div>
        )
    }
}

export default WhyPradium;