import React, {Component} from 'react';
import GrandBleu from '../pjeset/HowWeWork/GrandBleu';
import Table from '../pjeset/HowWeWork/Table';
import TwoThings from '../pjeset/Shared/TwoThings';
import Tttp from '../pjeset/Shared/Tttp';
import how1 from '../imazhet/how1-100.jpg';
import how2 from '../imazhet/how2-100.jpg';
import ShortInfo from '../pjeset/Shared/ShortInfo';
import Sitemap from '../pjeset/Shared/Sitemap';
import './HowWeWork.css';

function TintedImage({alt, src}){
   return( <div className="tinted">
        <img className="big" src={src} alt={alt} />
        <div className="overlay"></div>
    </div>)
}

class HowWeWork extends Component{
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div id="howWeWork">
                <GrandBleu selectedMenu = 'How we work'/>
                <TwoThings 
                    leftContent={<Tttp color='#3FA9F5' tt="Our Methodology" 
                                        p1="During the Engagement Phase we study your project requirements, environment and processes in-depth. This will result in a 360-overview of the future project that includes functional and technical requirements, architectural solution, development roadmap, project staffing, infrastructure, duration and costs, communication plan and project governance model." 
                                        p2="In many cases we prefer to start with an online or face-to-face meeting to maximize efficiency of communications and establish personal contact. But we will be even more happy to meet you at our headquarters, so you can experience in full our environment and culture."/>}
                    rightContent={<TintedImage src={how1} alt='Our Methodology' />}
                />
                <TwoThings style={{borderBottom: 'none'}}
                    leftContent={<Tttp color='#3FA9F5' tt="Business Models" p1="Well-defined business models are essential in order to set-up an outsourcing relationship. Companies demand from us flexibility 
                    to serve their unique business requirements, therefore we provide 
                    a range of business models to suit various requirements."/>}
                    rightContent={<p>We always look to become a strategic outsourcing partner and one-stop-shop service provider. We learn your culture and understand your business so we are able to efficiently manage all of your software needs. We work together with the client, share responsibilities and successes. Following predefined milestones, we fit the project team depending on your business needs.</p>}
                />
                <Table />
                <TwoThings 
                    leftContent={<Tttp color='#3FA9F5' tt="Team Structure" p1="A typical Scrum product team at PradiumTech includes 
                    a full-time product owner, a scrum master, developers, testers and a UI/UX designer. Together with our clients, 
                    we define in detail the roles, sprints, project artifacts, communication rules and acceptance criteria." p2="We are happy to work with our clients in order to accommodate requirements which would allow for a
                    better outcome of the project at hand as well as identify 
                    and use procedures which help deliver project in time 
                    and with desired quality."/>}
                    rightContent={<TintedImage src={how2} alt='Our Methodology' />}
                />
                <ShortInfo text='Albania is one of the most promising software outsourcing destinations in Southeastern Europe, as it is considered a stable location with a well-desired position on the global outsourcing map. It is an EU candidate country and this is an advantage for companies interested in collaborating with Albanian firms, as legal and financial concerns are reduced to a minimum.' />
                <Sitemap />
            </div>
        )
    }
}

export default HowWeWork;