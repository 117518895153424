import React, {Component} from 'react';
import ImageLink from './ImageLink';
import {Link} from 'react-router-dom';
import './ImageLinks.css';

class ImageLinks extends Component{
    
    render(){
        return(
            <div className="imageLinks">
                {this.props.imagelinks.map((il, i)=>{
                    return <Link key={i} to={il.to} ><ImageLink src = {il.bg} skeleti={il.skeleti} text={il.text} /></Link>
                })}
            </div>
        )
    }
}

export default ImageLinks;