import React, {Component} from 'react';
import GrandImageLink from '../pjeset/Shared/GrandImageLink';
import ShortInfo from '../pjeset/Shared/ShortInfo';
import LongInfo from '../pjeset/WhyOutsourcing/LongInfo';
import Sitemap from '../pjeset/Shared/Sitemap';
import i1 from '../imazhet/whyoutsource.jpg';
import skeleti1 from '../imazhet/skeleti1.svg';

class WhyOutsourcing extends Component{
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div id="whyOutsourcing">
                <GrandImageLink selectedMenu = '' src={i1} skeleti={skeleti1} text="Outsorcing as growth strategy"/>
                <ShortInfo text='Managers in developed countries are increasingly outsourcing as a potential source of competitiveness and value chain. if outsourcing initiative is thoughtfully pursued cost saving are not the main benefit of outsourcing. In addition, companies can employ an outsourcing strategy to better focus on core aspects of their business.'/>
                <LongInfo />
                <Sitemap />
            </div>
        )
    }
}

export default WhyOutsourcing;