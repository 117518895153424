import React from 'react';
import './Itl.css';
export default function Itl({ src, title, list, text }) {
    return (<div className="itl">
        <img src={src} alt={title} />
        <h1 className="titull">{title}</h1>
        <ul>
            {list ? list.map((li, i) => {
                return <li key={i}>{li}&nbsp;</li>;
            }) : null}
        </ul>
        <p>
            {text}
        </p>
    </div>);
}
