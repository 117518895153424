import React, {Component} from 'react';
import {IoIosArrowRoundForward} from 'react-icons/io';
import Menu from '../Shared/Menu';
import './Head.css';

class Head extends Component{
    render(){
        return(
            <div className="head">
                <Menu color={'black'} selected={this.props.selectedMenu}/> 
                <h1 className="title">Innovative software solutions using emerging technologies at an affordable cost.</h1>
                <div className="discover"><h4>Discover now &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4><IoIosArrowRoundForward size={38} color='#3FA9F5'/></div>
            </div>
        )
    }
}

export default Head;