import React, {Component} from 'react';
import GrandWhite from '../pjeset/Shared/GrandWhite';
import JoinUsLink from '../pjeset/JoinUs/JoinUsLink';
import TwoThings from '../pjeset/Shared/TwoThings';
import Itl from '../pjeset/Shared/Itl';
import joinUsImg from '../imazhet/about.jpg';
import joinus1 from '../imazhet/joinus1.svg';
import joinus2 from '../imazhet/joinus2.svg';
import joinus3 from '../imazhet/joinus3.svg';
import joinus4 from '../imazhet/joinus4.svg';
import joinus5 from '../imazhet/joinus5.svg';
import Sitemap from '../pjeset/Shared/Sitemap';

class JoinUs extends Component{
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div id="joinUs">
                <GrandWhite 
                    selectedMenu = 'Join us'
                    title='Great organizations are built by exceptional people.'
                    src={joinUsImg}
                    text=''
                />
                <TwoThings
                    leftContent={<Itl src={joinus1} title='Personal Growth' text='You will have the opportunity to learn from the best. We encourage all our employees to develop in their interested direction and continue acquiring new skills.' />}
                    rightContent={<Itl src={joinus2} title='Stability' text='Our company offers job security, due to the ongoing professional development and proven results in the software industry.' />} />
                <TwoThings
                    leftContent={<Itl src={joinus3} title='Project diversity' text='You will have the opportunity to work with different clients from different industries.' />}
                    rightContent={<p className="quote">“We consider every employee unique and thriving to continue the innovation and leadership culture. We are always looking for individuals with a passion for technology, with the courage to create original solutions.”</p>} />
                <TwoThings
                    leftContent={<Itl src={joinus4} title='Well-being' text='Our company offers key benefits and competitive salaries with a generous bonus scheme. We ensure that everyone is rewarded fairly.' />}
                    rightContent={<Itl src={joinus5} title='Working environment' text='We care greatly of our working environment so we have made our offices as comfortable and as enjoyable as possible.' />} />
                 <JoinUsLink />
                <Sitemap />
            </div>
        )
    }
}

export default JoinUs;