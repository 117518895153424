import React, {Component} from 'react';
import Posts from '../pjeset/Blog/Posts';
import Sitemap from '../pjeset/Shared/Sitemap';
import axios from 'axios';

class Blog extends Component{
    componentDidMount(){
        axios.get('http://localhost:1337/posts').then(res => console.log(res))
    }
    render(){
        return(
            <div id="blog">
                <Posts />
                <Sitemap />
            </div>
        )
    }
}

export default Blog;