import React, { Component } from "react";
import Form from "./Form";

import "./ApplicationForm.css";


class ApplicationForm extends Component {
  
  render() {
    return (
      <div id="apply" className="applicationForm">
        <div className="left">
          <div className="content">
            <h1 className="azzurro">Get started now!</h1>
            <p>
              Request a free quotation
            </p>
          </div>
        </div>
        <div className="right">
          <Form handleSubmit={this.handleSubmit} />
        </div>
      </div>
    );
  }
}

export default ApplicationForm;
