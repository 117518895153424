import React from 'react';
import './Tttp.css';

export default function Tttp({ tt, p1, p2, color }) {
    return (<div className="tttp">
        <h1 className="title" style={{color}}>{tt}</h1>
        <p>{p1}</p>
        <p>{p2}</p>
    </div>);
}
