import React, {Component} from 'react';
import Menu from '../Shared/Menu';
import Form from '../Shared/Form';
import biglogo from '../../imazhet/biglogo.svg';
import './ContactForm.css';

class ContactForm extends Component{
    render(){
        return(
            <>
                <Menu color='white' logoColor='black' selected={this.props.selectedMenu}/>
                <div className="contactForm"> 
                    <div className="left">

                    </div>
                    <div className="right">
                        <h1>Get started now</h1>
                        <Form />
                        <img src={biglogo} alt='decorative big logo'/>
                    </div>
                </div>
            </>
        )
    }
}

export default ContactForm;