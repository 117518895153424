import React, {Component} from 'react';
import Menu from '../Shared/Menu';
import {IoIosArrowBack, IoIosArrowForward} from 'react-icons/io';
import axios from 'axios';

import './Posts.css';

class Post extends Component{
    constructor(props){
        super(props);
        this.state = {
            active: 0
        }
        this.previousSlide = this.previousSlide.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
    }
    previousSlide(){
        let active = (this.state.active === 0) ? this.props.photos.length -1 : this.state.active - 1;
        this.setState({active});
    }
    nextSlide(){
        let active = (this.state.active === this.props.photos.length -1) ? 0 : this.state.active + 1;
        this.setState({active});
    }
    render(){
    const {title, head_photo, content, photos} = this.props;
    return (
        <div key={title} className="post">
            <img src={'http://localhost:1337' + head_photo.url}  alt={title}/>
            <h1>{title}</h1>
            <article>{content}</article>
            <div className="slider">
                <IoIosArrowBack size={50} color='#eee' className="arrow" onClick={this.previousSlide}/>
                {photos.map((photo, i) => {
                    return <img style={{left: i === this.state.active ? 0 : (i < this.state.active ? '-100%' : '100%' ) }} className={this.state.active === i ? 'active' : null} key={photo.url} src={'http://localhost:1337' + photo.url} alt={title}/>
                })}
                <IoIosArrowForward size={50} color='#eee' className="arrow" onClick={this.nextSlide}/>
            </div>
        </div>
    )
    }
}

class Blog extends Component{
    constructor(props){
        super(props);
        this.state = {
            posts: []
        }
    }
    componentDidMount(){
        axios.get('http://localhost:1337/posts').then(res => {
            let posts = res.data;
            this.setState({posts});
        })
    }
    render(){
        return(
            <>
                <Menu />
                <div className="posts">
                    {this.state.posts.map(post => {
                        return <Post {...post}/>
                    })}
                </div>
            </>
        )
    }
}

export default Blog;