import React, {Component} from 'react';
import adv from '../../imazhet/adv.svg';
import advv from '../../imazhet/advv.svg';
import advvv from '../../imazhet/advvv.svg';
import image4 from '../../imazhet/image4-100.jpg';
import './Advantages.css';
import TwoThings from '../Shared/TwoThings';
import Tttp from '../Shared/Tttp';

class Advantages extends Component{ 
    render(){
        return(
            <div className="advantages">
                <TwoThings 
                    leftContent={<h1 className="title">Top Advantages of Outsourcing to Albania</h1>} 
                    rightContent={<><p>Most investors chose to work with Albanian companies because of their excellent technical expertise, responsibility, flexibility, enthusiasm and great language skills.</p>
                                  <p>Choosing a software outsourcing company from Albania is a wise choice for long-term business collaborations in both nearshoring and offshoring services.</p></>} />
                <TwoThings 
                    leftContent={<Tttp color='#223780' tt='Experienced IT professionals' 
                                       p1='There is an increasing focus from the labour force to enter the software outsourcing market and the number increases every year.' 
                                       p2='Software developers are constantly learning and receiving certifications of their skills in technologies such as Java, C++, Mobile, PHP and many others.' 
                                  />}
                    rightContent={<img className="small" src={adv} alt='Experienced Its' />} />
                <TwoThings 
                    leftContent={<Tttp color='#223780' tt='IT Universitities and Hubs' 
                                       p1='Education plays a major role in preparing the next generation of developers. In the last few years, many new strategies have been adopted in order to strengthen the relationships between business sector and  education establishments.' 
                                       p2='As a result, Albanian business sector is taking a more active role in organising workshops, sponsoring academies and expand internship programs in order to provide practical opportunities for potential candidates.' 
                                 />}
                    rightContent={<img className="big" src={image4} alt='IT Universitities and Hubs' />}
                />
                <TwoThings
                    leftContent={<Tttp color='#223780' tt='Cost saving by working with a software company from Albania' 
                                       p1={<img className="small" src={advv} alt='Cost saving'/>} 
                                 />}
                    rightContent={<Tttp color='#223780' p2='Albania is one of the most competitive countries in Europe, in terms of costs efficiency for outsourcing software projects. By working with a software outsourcing company from Albania, you can benefit from receiving good products and services at low cost.' />}
                />
                <TwoThings
                    leftContent={<Tttp color='#223780' tt='Multilingual communication and environment' 
                                       p1='Albanian developers are young, motivated and multilingual specialists. You can easily communicate with them in English, Italian, German and in many other languages.' 
                                       p2='Albanian people are open-minded, have excellent intercultural skills and value international cultures.'
                                  />}
                    rightContent={<Tttp color='#223780'  p1={<img className="small" src={advvv} alt='Info translation'/>} p2='All the information you provide will be well translated in the final results, meeting all your expectations and delivering professional products.' />}
                />
                <TwoThings 
                    leftContent={<Tttp color='#223780' tt='Time efficiency and ease of access' 
                                       p1='Traveling to Albania is Visa Free for many countries around the world and all of EU Nationals. We are located in GMT+1 Time Zone, thus a client based in Europe will be able to collaborate with outsourcing teams in real time during office hours.' 
                                       p2='We are also able to adapt to north American firms covering all of their working day hours.' 
                                 />}
                    rightContent={<img className="big" src={image4} alt='Time efficiency and ease of access' />}
                />
            </div>
        )
    }
}

export default Advantages;