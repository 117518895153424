import React, {Component} from 'react';
import GrandImageLink from '../pjeset/Shared/GrandImageLink';
import blogImg from '../imazhet/blog.jpg';
import Sitemap from '../pjeset/Shared/Sitemap';
import axios from 'axios';

class Blog extends Component{
    componentDidMount(){
        axios.get('http://localhost:1337/posts').then(res => console.log(res))
    }
    render(){
        return(
            <div id="blog">
                <GrandImageLink selectedMenu = 'Blog' src={blogImg} text='Coming soon!'/>
                <Sitemap />
            </div>
        )
    }
}

export default Blog;