import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {IoIosMenu, IoIosClose} from 'react-icons/io'
import logo from '../../logo.svg';

import './Menu.css';

class MobileMenu extends Component{ 
    constructor(props){
        super(props);
        this.state = {menuOpen: false}
    }
    render(){
        let icon = this.state.menuOpen ? <IoIosClose onClick={()=>this.setState({menuOpen: !this.state.menuOpen}, this.props.setMenuUp(!this.state.menuOpen))} size={33} className="hamburger" style={{zIndex: 10, marginTop: '18px', fill: '#3FA9F5'}} /> 
                                       : <IoIosMenu  onClick={()=>this.setState({menuOpen: !this.state.menuOpen}, this.props.setMenuUp(!this.state.menuOpen))} size={33} className="hamburger" style={{zIndex: 10, marginTop: '18px', fill: '#3FA9F5'}} />;
        return(
            <div className="mobileWrapper">
                <nav className='mobile' style={{display: this.state.menuOpen ? 'flex' : 'none'}}>
                    <Link style={{display: 'inline-block'}} to="/" className="menuLogo"><img src={logo} alt="PradiumTech"/></Link>
                    <Link className={this.props.selected === 'About us' ? 'selected' : ''} to="/about">About us</Link>
                    <Link className={this.props.selected === 'Expertise' ? 'selected' : ''} to="/expertise">Expertise</Link>
                    <Link className={this.props.selected === 'How we work' ? 'selected' : ''} to="/howwework">How we work</Link>
                    {/* <Link className={this.props.selected === 'Blog' ? 'selected' : ''} to="/blog">Blog</Link> */}
                    <Link className={this.props.selected === 'Join us' ? 'selected' : ''} to="/joinus">Join us</Link>
                    <Link className={this.props.selected === 'Contact' ? 'selected' : ''} to="/contact">Contact</Link>
                </nav>
                {icon}
                <div style={{height: this.state.menuOpen ? '100%' : 0, borderRadius: this.state.menuOpen ? 0 : '10%'}} className="reveal"></div>
            </div>
        )
    }
}

function DesktopMenu ({color, selected}){ 
    return(
        <nav className='desktop' >
            <Link style={{color}} className={selected === 'About us' ? 'selected' : ''} to="/about">About us</Link>
            <Link style={{color}} className={selected === 'Expertise' ? 'selected' : ''} to="/expertise">Expertise</Link>
            <Link style={{color}} className={selected === 'How we work' ? 'selected' : ''} to="/howwework">How we work</Link>
            {/* <Link style={{color}} className={selected === 'Blog' ? 'selected' : ''} to="/blog">Blog</Link> */}
            <Link style={{color}} className={selected === 'Join us' ? 'selected' : ''} to="/joinus">Join us</Link>
            <Link style={{color}} className={selected === 'Contact' ? 'selected' : ''} to="/contact">Contact</Link>
        </nav>
    )
}

class Menu extends Component{ 
    constructor(props){
        super(props);
        this.state = {w: window.document.body.offsetWidth, menuOpen: false}
        this.getMenu = this.getMenu.bind(this);
    }
    componentDidMount(){
        let that = this;
        window.addEventListener('resize', function(){
            let w = window.document.body.offsetWidth;
            that.setState({w})
        })
    }
    getMenu(menuOpen){
        this.setState({menuOpen})
    }
    render(){
        const nav = this.state.w < 1000 ? <MobileMenu color={this.props.color} setMenuUp={this.getMenu} selected={this.props.selected}/> 
                                        : <DesktopMenu color={this.props.color} selected={this.props.selected}/>

        let navstyle = {position: this.state.menuOpen ? 'fixed' : 'absolute', height: this.state.w < 1000 ? (this.state.menuOpen ? '100vh' : 0) : 'auto'};   
        return(
            <div className="navbar" style={navstyle}>
                <Link className="logo" to="/">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 188.74 29.59" width='180' height='30'>
                        <path fill='#3fa9f5' d="M28.3,8.37,1.36,0A1,1,0,0,0,0,1.28l6.26,27.5A1,1,0,0,0,8,29.31L28.71,10.14a1,1,0,0,0-.4-1.77ZM12.95,13.89l11.47-2.62L9.47,25.12ZM7.47,24.51,2.93,4.62l8,8.65Zm5-12.67-8-8.65L24,9.22Z"/>
                        <path fill={this.props.logoColor || this.props.color} d="M34.05,6.82h9.11c3.9,0,6.51,2.31,6.51,6.36s-2.6,6.39-6.51,6.39H38v7.49h-3.9Zm8.68,9.28a2.72,2.72,0,0,0,3-2.92,2.7,2.7,0,0,0-3-2.89H38V16.1Z" />
                        <path fill={this.props.logoColor || this.props.color} d="M51,12.6h3.76V15.5a4.1,4.1,0,0,1,4.19-3h.72v3.47h-1.3c-2.46,0-3.62,1.16-3.62,3.62v7.52H51Z" />
                        <path fill={this.props.logoColor || this.props.color} d="M68.62,25.3a5.1,5.1,0,0,1-4.28,2.05c-2.6,0-4.74-1.65-4.74-4.25,0-3.12,2.43-4.54,5.29-4.77L68.53,18v-.35a2.22,2.22,0,0,0-2.34-2.31H66a2.19,2.19,0,0,0-2.37,1.74H59.88c.29-2.72,2.6-4.77,6.22-4.77s6.07,1.82,6.07,5.21v6.36a24.51,24.51,0,0,0,.14,3.18H68.79A13,13,0,0,1,68.62,25.3Zm-3.36-1a3.17,3.17,0,0,0,3.27-3.15v-.41l-3.12.32c-1,.09-2.11.58-2.11,1.76,0,.9.78,1.48,1.82,1.48Z" />
                        <path fill={this.props.logoColor || this.props.color} d="M73.65,19.84c0-3.9,2.23-7.52,6.42-7.52a4.92,4.92,0,0,1,4.17,2.17V6.82H88V27.07H84.24V25.19a4.92,4.92,0,0,1-4.17,2.17C75.88,27.36,73.65,23.74,73.65,19.84Zm7.29,4.48c1.74,0,3.3-1.74,3.3-4.48s-1.56-4.48-3.3-4.48h-.23c-1.88,0-3.3,1.71-3.3,4.45s1.42,4.51,3.3,4.51Z" />
                        <path fill={this.props.logoColor || this.props.color} d="M90.28,6.82h4v3.9h-4Zm.14,5.78h3.76V27.07H90.43Z"/>
                        <path fill={this.props.logoColor || this.props.color} d="M105.61,12.6h3.76V27.07h-3.76V25a4.87,4.87,0,0,1-4.22,2.4c-3.38,0-4.89-2.31-4.89-5.5V12.6h3.76v8.82c0,1.88.78,2.89,2.14,2.89h.23c1.65,0,3-1.65,3-3.67Z"/>
                        <path fill={this.props.logoColor || this.props.color} d="M115.56,27.07H111.8V12.6h3.76v2.05a4.93,4.93,0,0,1,4.31-2.34,4.6,4.6,0,0,1,4.43,2.49,5.44,5.44,0,0,1,4.77-2.49c3.76,0,5.15,2.46,5.15,5.64v9.11h-3.76V18.39c0-1.88-.67-2.89-2.26-2.89H128A3.27,3.27,0,0,0,124.91,19v8h-3.76V18.39c0-1.88-.69-2.89-2.29-2.89h-.17A3.27,3.27,0,0,0,115.56,19Z"/>
                        <path fill='#3fa9f5' d="M138.73,10.29h-7V6.82h17.93v3.47h-7V27.07h-3.9Z" />
                        <path fill='#3fa9f5' d="M153.31,27.36c-3.62,0-6.88-2.75-6.88-7.52,0-4.34,2.55-7.52,6.88-7.52s6.59,3.15,6.59,7.35c0,.43,0,.9-.06,1.36h-9.78a3.43,3.43,0,0,0,3.3,3.3h.17a2.49,2.49,0,0,0,2.57-1.82h3.73C159.15,25.33,156.93,27.36,153.31,27.36Zm-3.24-9.23h6.19a2.86,2.86,0,0,0-3-2.78h-.17A2.86,2.86,0,0,0,150.07,18.13Z"/>
                        <path fill='#3fa9f5' d="M174.08,22c-.29,3.62-2.75,5.35-6.51,5.35s-6.94-2.89-6.94-7.52,3-7.52,6.94-7.52,6.22,1.74,6.51,5.35h-3.76a2.48,2.48,0,0,0-2.66-2.31h-.17c-2.17,0-3.09,2-3.09,4.48s.93,4.48,3.09,4.48h.17A2.48,2.48,0,0,0,170.32,22Z"/>
                        <path fill='#3fa9f5' d="M179.34,27.07h-3.76V6.82h3.76v7.9a5,5,0,0,1,4.37-2.4c3.47,0,5,2.31,5,5.5v9.26H185V18.25c0-1.88-.84-2.89-2.29-2.89h-.23A3.43,3.43,0,0,0,179.34,19Z"/>
                    </svg>
                </Link>
                {nav}
            </div>
        )
    }
}

export default Menu;