import React, {Component} from 'react';
import GrandImageLink from '../pjeset/Shared/GrandImageLink';
import BigInfo from '../pjeset/ExpertiseX/BigInfo';
import Sitemap from '../pjeset/Shared/Sitemap';
import i1 from '../imazhet/webdesktop.jpg';
import i2 from '../imazhet/mobileapp.jpg';
import i3 from '../imazhet/managedservices.jpg';
import i4 from '../imazhet/databaseossupport.jpg';
import i5 from '../imazhet/customersupport.jpg';
import i6 from '../imazhet/testing.jpg';
import skeleti1 from '../imazhet/skeleti4.svg';
import skeleti2 from '../imazhet/skeleti1.svg';
import skeleti3 from '../imazhet/skeleti5.svg';
import skeleti4 from '../imazhet/skeleti6.svg';
import skeleti5 from '../imazhet/skeleti7.svg';
import skeleti6 from '../imazhet/skeleti8.svg';
import exp1 from '../imazhet/exp1.svg';
import exp2 from '../imazhet/exp2.svg';
import exp3 from '../imazhet/exp3.svg';
import exp4 from '../imazhet/exp4.svg';
import exp5 from '../imazhet/exp5.svg';
import exp6 from '../imazhet/exp6.svg';

const data = [
    {bg: i1, skeleti: skeleti1, to: '/expertise/webdesktop',text: 'Web and Desktop', 
     tt:'Web Systems, as the foundation of high performance in today’s business environment', 
     p1:'Web Systems must be agile, cost-effective and able to use disruptive technology. Long-term experience combined with the best practices, passion and teamwork enable us to develop applications implemented on top of robust web architectures.', 
     p2:'Our team is specialized in rapid and error-free development of easily maintainable and scalable applications that fit specific business needs.', 
     src: exp1},
    {bg: i2, skeleti: skeleti2, to: '/expertise/mobileapps',text: 'Mobile Applications', 
    tt:'From standalone Apps & Games to Social Networks, we deliver high-quality products.', 
    p1:'Our integrated team of specialists provide strategy, architecture, design, security and development for Mobile Applications.', 
    p2:'We offer a broad range of development services on leading mobile platforms including iOS and Android.', 
    src:exp2},
    {bg: i3, skeleti: skeleti3, to: '/expertise/managedservices',text: 'Managed Services', 
    tt:'IT Support, Monitoring Services and active Technology Support', 
    p1:'PradiumTech provides traditional IT support and monitoring services as well as active technology support, all at a predictable monthly subscription rate and customized to your business needs.', 
    p2:'', 
    src:exp3},
    {bg: i4, skeleti: skeleti4, to: '/expertise/dbos',text: 'Database and OS Support', 
    tt:'MySQL, SQL Server and Oracle', 
    p1:'We provide support in a number of Database and Operating Systems, such as MySQL, SQL Server and Oracle.', 
    p2:'We have a number of trained engineers with proven experience in Windows Server and Ubuntu operating systems.', 
    src:exp4},
    {bg: i5, skeleti: skeleti5, to: '/expertise/csupport',text: 'Customer Support', 
    tt:'Tier 1 & Tier 2 customer support.', 
    p1:'PradiumTech can provide Tier 1 and Tier 2 customer support for all industries and in multiple languages such as English, Italian and German.', 
    p2:'', 
    src:exp5},
    {bg: i6, skeleti: skeleti6, to: '/expertise/qatesting',text: 'QA and Testing', 
    tt:'Expertise on Web, Desktop and Mobile', 
    p1:'We advise on choosing the right tools or easily adapt to using your preferred tools, whichever is more efficient.', 
    p2:'We cover functional and non-functional QA assurance requirements on any project by performing integration testing, system testing acceptance testing and compatibility testing. ', 
    src:exp6}
]

class ExpertiseX extends Component{
    constructor(props){
        super(props);
        this.state = {i: 0}
    }
    componentDidMount(){
        const locations = data.map(d=>d.to.replace('/expertise/', ''));
        let location = this.props.match.params.which;
        let i = locations.indexOf(location);
        this.setState({i});
        window.scrollTo(0, 0);
    }
    render(){
        const {bg, skeleti, text, tt, p1, p2, src} = data[this.state.i];
        return(
            <div className="ExpertiseX">
                <GrandImageLink selectedMenu = 'Expertise' src={bg} skeleti={skeleti} text={text}/>
                <BigInfo tt={tt} p1={p1} p2={p2} src={src}/>
                <Sitemap />
            </div>
        )
    }
}

export default ExpertiseX;