import React, {Component} from 'react';
import GrandImageLink from '../pjeset/Shared/GrandImageLink';
import thankYouImg from '../imazhet/thankYou.jpg';
import Sitemap from '../pjeset/Shared/Sitemap';

class ThankYou extends Component{
    render(){
        return(
            <div id="thankYou">
                <GrandImageLink src={thankYouImg} text='Thank you for reaching out!'/>
                <Sitemap />
            </div>
        )
    }
}

export default ThankYou;