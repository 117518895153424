import React, {Component} from 'react';
import Menu from '../Shared/Menu';
import biGlogo from '../../imazhet/bgskeletonlogo_1.svg';
import './GrandBleu.css';

function Step({title, descs}){
    return(
        <div className="step">
            <h4 className="title">{title}</h4>
            {descs.map(desc=>{
                return <p key={desc}>{desc}</p>
            })}
        </div>
    )
}

class GrandBleu extends Component{
    render(){
        return(
            <div>
                <Menu color='white' selected={this.props.selectedMenu}/>
                <div className="grandBleu"> 
                    <h1 className="title">Engage with PradiumTech in 7 simple steps.</h1>
                    <div className="steps">
                        <Step title="Step 1: Introduction" descs={["Company introduction, executive briefing","Project description, project scope, technology and business model"]}/>
                        <Step title="Step 2: Non-Disclosure Agreement" descs={["Respond to RFI if applicable", "Sign a mutual non-disclosure agreement"]}/>
                        <Step title="Step 3: Project Analysis" descs={["Share project and system requirements","Clarification sessions", "We provide flexibility on any project"]}/>
                        <Step title="Step 4: Financial Offer" descs={["We send you an offer, including the proposal for a business model, technology to be used and project timeframe."]}/>
                        <Step title="Step 5: Contract" descs={["Signing the contract by both parties or issue of purchase order where it applies.","Milestones and KPI acceptance."]}/>
                        <Step title="Step 6: Team meeting" descs={["Team completion","Team Introduction"]}/>
                        <Step title="Step 7: Project Start" descs={["Project kick-off"]}/>
                    </div>
                    <img src={biGlogo} alt='background decoration, nothing more' />
                </div>
            </div>
        )
    }
}

export default GrandBleu;