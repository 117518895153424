import React, {Component} from 'react';
import GrandImageLink from '../pjeset/Shared/GrandImageLink';
import ShortInfo from '../pjeset/Shared/ShortInfo';
import Advantages from '../pjeset/WhyAlbania/Advantages';
import Sitemap from '../pjeset/Shared/Sitemap';
import i3 from '../imazhet/whyalbania.jpg';
import skeleti2 from '../imazhet/skeleti2.svg';

class WhyAlbania extends Component{
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div id="whyAlbania">
                <GrandImageLink selectedMenu = '' src={i3} skeleti={skeleti2} text="Why outsource to Albania"/>
                <ShortInfo text='Albania is one of the most promising software outsourcing destinations in SouthEastern Europe.  It is considered as a stable location with a strategic position on the global outsourcing map. It is an EU candidate country, with an advantage for companies interested in collaborating with Albanian firms, as legal and financial concerns are reduced to a minimum.' />
                <Advantages />
                <Sitemap />
            </div>
        )
    }
}

export default WhyAlbania;