import React, {Component} from 'react';
import ContactForm from '../pjeset/Contact/ContactForm';
import Sitemap from '../pjeset/Shared/Sitemap';

class Contact extends Component{
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div id="contact">
                <ContactForm selectedMenu = 'Contact'/>
                <Sitemap />
            </div>
        )
    }
}

export default Contact;